<template>
  <div class="remove-app">
    <RemoveComp />
  </div>
</template>

<script>
// @ is an alias to /src
import RemoveComp from "@/components/RemoveComp.vue";

export default {
  name: "RemoveApp",
  components: {
    RemoveComp,
  },
  mounted() {
    console.log("Remove App Page Mounted");
  },
  computed: {},
  watch: {},
};
</script>
