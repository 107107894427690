<template>
  <div>
    <div class="container">
      <form @submit.prevent="submit">
        <div class="input-container">
          <label>App Name: </label>
          <input v-model="name" type="text" placeholder="Search..." />
        </div>
        <div class="input-container" v-for="app in findRemove" :key="app.name">
          <button style="width: 100%" @click.prevent="name = app.name">
            {{ app.name }}
          </button>
        </div>
        <button id="delBtn" type="submit">Remove</button>
      </form>
    </div>
  </div>

  <router-view />
</template>

<script>
export default {
  name: "RemoveComp",
  data: () => ({
    name: "",
  }),
  mounted() {
    if (this.app) this.name = this.app.name;
  },
  computed: {
    app() {
      if (this.$route.params.name) {
        return this.$store.getters["apps/app"](this.$route.params.name);
      }
      return null;
    },
    apps() {
      return this.$store.getters["apps/apps"];
    },
    findRemove() {
      if (!this.name) return [];
      return this.$store.getters["apps/appsSearch"](this.name);
    },
  },

  methods: {
    submit() {
      const valid = !!this.apps.filter((a) => a.name == this.name).length;
      if (valid) {
        this.$store.dispatch("apps/delApp", this.name);
      }
      this.name = "";
      if (!valid) {
        this.$store.dispatch("general/setAlert", {
          type: "failed",
          msg: "App NOT found!",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
  form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    .input-container {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      padding: 8px 6px;
      margin-bottom: 6px;
      label {
        margin-inline-end: 10px;
      }
    }
  }
}
</style>
